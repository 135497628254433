<template>
  <v-dialog v-model="show" fullscreen full-width>
    <v-container
      fluid
      fill-height
      style="background-color: rgba(255, 255, 255, 0.9);"
    >
      <v-layout justify-center align-center>
        <v-progress-circular
          :size="70"
          :width="5"
          indeterminate
          color="primary"
        >
        </v-progress-circular>
      </v-layout>
    </v-container>
  </v-dialog>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
export default {
  // https://zaengle.com/blog/using-v-model-on-nested-vue-components
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
