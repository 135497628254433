<template>
  <progress-modal v-if="loading" :show="true" />
  <v-container v-else class="containerLogin" fluid fill-height>
    <v-row align="center" style="height: 100vh" no-gutters>
      <v-col  v-if="isWeb" md="7" sm="12" cols="12">
        <v-img
          class="hidden-sm-and-down img-login"
          src="@/assets/background-login.png"
        ></v-img>
        <v-img
          class="hidden-md-and-up img-login"
          src="@/assets/background-login.png"
          max-height="300"
          max-width="300"
        ></v-img>
      </v-col>
      <v-col md="5" sm="12" cols="12">
        <v-row >
          <v-img contain class="img-logo" src="https://s3.us-east-1.amazonaws.com/milgrau/mg/imagem/milgrau.png" />
        </v-row>
        <v-card class="pa-2" flat tile>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="validateBeforeSubmit"
          >
            <v-card flat>
              <v-card-title> Faça login </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="usuario"
                      :rules="[rules.required, rules.email]"
                      label="Usuário ou e-mail cadastrado"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="senha"
                      :rules="[rules.required]"
                      label="Senha"
                      type="password"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <a href="/esqueci-minha-senha">Esqueci a senha</a>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      :disabled="!valid"
                      :loading="loading"
                      class="text-capitalize"
                      color="primary"
                      type="submit"
                    >
                      Entrar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import progressModal from "@/components/progress-modal.vue";
import { UserService } from "@/services";

export default {
  components: { progressModal },
  data() {
    return {
      valid: false,
      loading: false,
      usuario: "",
      senha: "",
      rules: {
        required: value => !!value || "Campo obrigatório.",
        counter: value => value.length <= 80 || "Max 80 caracteres",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Endereço de email inválido.";
        }
      }
    };
  },
  computed: {
    isWeb() {
      return this.$vuetify.breakpoint.width > 750;
    }
  },
  methods: {
    validateBeforeSubmit() {
      if (this.$refs.form.validate()) {
        this.login();
      } else {
        this.$root.showErro("Informe um e-mail e uma senha");
      }
    },
    login() {
      this.loading = true;
      let self = this;
      UserService.logar(this.usuario, this.senha)
        .then(response => {
          let token = response.data;
          self.$store.commit("user/setTokenAuth", token);
          self.$store.commit("user/setAuthStatus", true);
        })
        .then(() => {
          UserService.getDadosUsuario(this.usuario)
            .then(response => {
              self.$store.commit("user/setUserData", response.data);
              window.location.reload();
            })
            .catch(error => {
              this.$root.showErro(error.data);
              this.loading = false;
            });
        })
        .catch(() => {
          this.$root.showErro("Usuario ou senha inválidos");
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.containerLogin {
  padding: 0;
  margin: 0;
  background: #ffffff;
}
.img-login {
  position: relative;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}
.img-logo {
  height: 140px;
  width: 180px;
}
</style>
