<template>
  <progress-modal v-if="loading" :show="true" />
  <v-container v-else class="container-login-form-ingresso fill-height" fluid>
    <v-row align="center" style="height: 100vh" no-gutters>
      <v-col md="7" sm="12" cols="12">
        <!--  -->
      </v-col>
      <v-col md="5" sm="12" cols="12">
        <v-row justify="center" class="mb-10">
          <v-img contain src="@/assets/ingressoLivre/logo.png" max-height="250" max-width="250"></v-img>
        </v-row>
        <v-card class="pa-2" flat tile style="background-color: transparent !important">
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validateBeforeSubmit">
            <v-card flat style="background-color: transparent !important">
              <v-card-title>
                <span style="color: var(--v-white-base)">Faça login</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="usuario" :rules="[rules.required, rules.email]" class="login-text-field"
                      label="Usuário ou e-mail cadastrado"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="senha" :rules="[rules.required]" class="login-text-field" label="Senha"
                      type="password" name="password" autocomplete="new-password"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <a href="/esqueci-minha-senha" style="color: var(--v-white-base)">Esqueci a senha</a>
                  </v-col>
                  <v-col cols="12">
                    <v-btn :disabled="!valid" :loading="loading" class="btn-login-form" color="primary" type="submit">
                      Entrar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import progressModal from "@/components/progress-modal.vue";
import { UserService } from "@/services";

export default {
  components: { progressModal },
  data() {
    return {
      valid: false,
      loading: false,
      usuario: "",
      senha: "",
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        counter: (value) => value.length <= 80 || "Max 80 caracteres",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Endereço de email inválido.";
        },
      },
    };
  },
  methods: {
    validateBeforeSubmit() {
      if (this.$refs.form.validate()) {
        this.login();
      } else {
        this.$root.showErro("Informe um e-mail e uma senha");
      }
    },
    login() {
      this.loading = true;
      let self = this;
      UserService.logar(this.usuario, this.senha)
        .then((response) => {
          let token = response.data;
          self.$store.commit("user/setTokenAuth", token);
          self.$store.commit("user/setAuthStatus", true);
        })
        .then(() => {
          UserService.getDadosUsuario(this.usuario)
            .then((response) => {
              self.$store.commit("user/setUserData", response.data);
              window.location.reload();
            })
            .catch((error) => {
              this.$root.showErro(error.data);
              this.loading = false;
            });
        })
        .catch(() => {
          this.$root.showErro("Usuario ou senha inválidos");
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.btn-login-form {
  color: var(--v-white-base);
  text-transform: unset !important;
}
</style>

<style lang="scss">
$maxWidth: 750px;

.container-login-form-ingresso {
  background-color: var(--v-backLogin-base);
  background-image: url("../../assets/ingressoLivre/background-login-full.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-text-field input,
.login-text-field .v-label,
.login-text-field input::placeholder,
.login-text-field .v-input__slot::before {
  color: var(--v-white-base) !important;
  border-color: var(--v-loginForm-base) !important;
}

.v-btn--outlined {
  border: thin solid var(--v-loginForm-base);
}

.btn-login-form {
  color: var(--v-white-base);
  text-transform: unset !important;
}
</style>