<template>
  <div>
    <influencersLogin v-if="isInfluencers()"></influencersLogin>
    <ingressoLivreLogin v-else-if="isIngressoLivre()"></ingressoLivreLogin>
    <concaLogin v-else-if="isConca()" />
    <belaentradaLogin v-else-if="isBelaEntrada()" />
    <sbeventosLogin v-else-if="isSBEventos()" />
    <elfortLogin v-else-if="isElfort()" />
    <porterLogin v-else-if="isPorter()" />
    <ptLogin v-else-if="isPt()" />
    <milgrauLogin v-else-if="isMilgrau()"></milgrauLogin>
    <!-- <canvasTest ></canvasTest> -->
  </div>
</template>

<script>
import ingressoLivreLogin from "./ingressoLivre/login.vue";
import influencersLogin from "./influencers/login.vue";
import concaLogin from "./conca/login.vue";
import milgrauLogin from "./milgrau/login.vue";
import belaentradaLogin from "./belaentrada/login.vue";
import sbeventosLogin from "./sbeventos/login.vue";
import elfortLogin from "./elfort/login.vue";
import porterLogin from "./porter/login.vue";
import ptLogin from "./pt/login.vue";
// import canvasTest from "./canvas/jquery.vue";

export default {
  components: {
    influencersLogin,
    belaentradaLogin,
    milgrauLogin,
    ingressoLivreLogin,
    concaLogin,
    sbeventosLogin,
    elfortLogin,
    porterLogin,
    ptLogin,
    // canvasTest
  },
};
</script>
